.lable-and-filed{
    @apply flex flex-col w-full gap-0.5 
}

.label{
    @apply text-[14px] text-shadow-black text-white
}

.input-coantiner{
    @apply relative flex flex-col justify-center items-end
}

.input{
    @apply border  p-2 text-[14px] w-full rounded-md outline-none shadow-md text-black
}
