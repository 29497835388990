.modal-container{
    @apply absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4  
}

.modal{
    background: linear-gradient(180deg, #010D1E 0%, #1A59AE 100%);
    @apply flex  w-full max-w-screen-md overflow-y-auto max-h-screen  shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col
}

.modal-header{
    @apply flex w-full h-fit items-center justify-between font-semibold
}

.feild-conatainer{
    @apply flex w-full h-fit justify-between gap-5
}

.lable-and-filed{
    @apply flex flex-col w-full gap-0.5 
}

.label{
    @apply text-[14px] text-shadow-black 
}

.input-coantiner{
    @apply relative flex flex-col justify-center items-end
}

.input{
    @apply border  p-2 text-[14px] w-full rounded-md outline-none shadow-md 
}

.label-and-button{
    @apply flex w-full h-fit justify-end
}

.image-label{
    @apply text-[14px] flex md:hidden text-shadow-black 
}

.degree-add-button-container{
    @apply flex w-fit h-fit  bg-gray-300 rounded-md gap-4
}

.degree-add-button{
    @apply border bg-white text-xs p-1 rounded-md duration-200 active:translate-x-0 active:-translate-y-0 translate-x-1 -translate-y-1
}

.cancle-button{
    @apply w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center 
}

.submit-button{
    @apply w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center 
}

.error-message{
    @apply flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold
}