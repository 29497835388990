.department-header{
    @apply flex w-full items-center gap-5
}

.department-header-search-container{
    @apply flex w-full h-full justify-center items-center
}

.department-info-container{
    @apply flex w-full p-3  flex-col
}

.department-info-header{
    @apply flex w-full justify-between items-center h-fit
}

.department-add-button{
    @apply bg-secondary text-white   text-sm rounded-md gap-2 p-2 active:bg-secondary items-center
}