.radio-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  font-size: 14px;
  color: white;
}

.required {
  color: red;
}

.radio-options {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.radio-option {
  display: flex;
  align-items: center;
  color: white;
}

.radio-input {
  margin-right: 8px;
}

.radio-label {
  font-size: 14px;
}
