.file-input-container{
    @apply flex w-full h-fit justify-end
}

.file-input{
    @apply border border-dashed border-gray-400 text-[14px]  w-full flex justify-center sm:justify-between h-[80px]   rounded-md outline-none shadow-md items-center px-5
}

.upload-icon-container{
    @apply hidden  sm:flex  w-fit h-full   justify-center items-center
}

.file-input-content{
    @apply w-fit h-full flex justify-center md:justify-around gap-5 items-center  text-gray-500
}

.file-input-text{
    @apply flex text-[14px] justify-center items-center
}

.file-input-button{
    @apply flex justify-center items-center py-1  md:py-2 px-5 w-fit h-fit border rounded-full 
}