@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-4xl;
    @apply font-bold;
  }
  h2 {
    @apply text-3xl;
    @apply font-bold;
  }
  h3 {
    @apply text-2xl;
    @apply font-bold;
  }
  h4 {
    @apply text-xl;
    @apply font-semibold;
  }
  h5 {
    @apply text-base;
    @apply font-semibold;
  }
  h6 {
    @apply text-sm;
    @apply font-semibold;
  }
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


*::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

@media screen and (max-width: 2) {
  *::-webkit-scrollbar {
    display: none;
  }
}

*::-webkit-scrollbar-track {
  display: none;
}

*::-webkit-scrollbar-thumb {
  background: rgba(49, 130, 122, 0.3);
  border-radius: 0.8em;
}

*::-webkit-scrollbar-thumb:hover {
  background: rgba(49, 130, 122, 0.76);
}

*::-webkit-scrollbar-track {
  position: absolute;
  top: 0;
}

* {
  text-overflow: ellipsis;
  box-sizing: border-box;
}

@layer utilities {
  .text-shadow-black {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  }
}

ul, ol { 
  display: block; 
  list-style-type: disc; 
  -webkit-margin-before: 1em; 
  -webkit-margin-after: 1em; 
  -webkit-margin-start: 0px; 
  -webkit-margin-end: 0px;
  -webkit-padding-start: 40px;
}